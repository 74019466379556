.whiteLogoMasterChild {
    position: relative;
    width: 2.06rem;
    height: 2.56rem;
  }
  .geogaming {
    position: relative;
    font-weight: 600;
    color : var( --blanc);
    
  }
  .geogaming:hover{
  color : var( --bleu-green);
  }
  .logoBlanc {
    position: relative;
    width: 13rem;
    height: 2.94rem;
  }
  .logo,
  .masterBtnMedium {
    display: flex;
    justify-content: flex-start;
  }
  .logo {
    flex: 1;
    flex-direction: column;
    padding: var(--padding-3xs) 0;
    align-items: flex-start;
  }
  .whiteLogoMaster {
  
    top: 0.21rem;
    left: 0.21rem;
    gap: 0.56rem;
    position: relative;
    width: 13rem;
    height: 2.94rem;
    text-decoration: none;
  }
  .geogamingWrapper,
  .whiteLogoMaster {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .geogamingWrapper {
    padding: 0.38rem var(--padding-3xs) var(--padding-3xs) 0;
  }
  .imgLogo{
    width:3rem;
  }