.containerCard {
    display: flex;
    max-width:21.063rem ;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    flex-shrink: 0;
    border-radius: 0.9375rem;
    border: 1px solid var(--background-light-grey, #ECECEC);
    background: #FFF;
    /* bloc shadow */
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
}
.containerImgCard{
    display: flex;
    height: 15.6875rem;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: center;
    border-radius: 0.9375rem 0.9375rem 0 0;
    overflow: hidden; 

}

.containerTitleParagraphe{
    display: flex;
    padding: 0rem 0.9375rem 1.88rem 0.9375rem ;
    flex-direction: column;
    align-items: center;
    gap: 1.25rem;
    align-self: stretch;
}
