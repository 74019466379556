

  .titreHome {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    padding: 0;
    align-items: flex-start;
    justify-content: center;
    z-index: 0;
  }
  .punchlineHome {
    margin: 0;
    text-shadow: 2px 2px 4px var(--grey-background);
    font-size: 1.375rem; /* Adjust the value as needed */
    font-family: Comfortaa;
    font-style: normal;
    font-weight: 400;
    line-height: 2.2rem;
    text-align: center;
  }
  
  @media (max-width: 700px) {
    .punchlineHome {
      font-size: 1.375rem;; 

    }
  }
  

  
  .ContainerHome {
    flex: 1;
    position: relative;
    line-height: 2.5rem;
  }
  .paragrapheHome {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-3xs) 1.25rem;
    align-items: flex-start;
    justify-content: center;
    z-index: 1;
    font-size: var(--h4-comfortaa-size);
  }
  
  .containerHome,
  .videoHome {
    align-self: stretch;
    position: relative;
    margin :0 auto;
    width:100%;
  }
  .containerHome {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1.44rem;
    text-align: center;
    font-size: var(--h1-size);
    font-family: var(--h4-comfortaa);
  }
  .videoHome {
    max-width: 75rem;
    overflow: hidden;
    height: 36.5rem;
    flex-shrink: 0;
    object-fit: cover;
  }
  .accueil,
  .containerVideo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .containerVideo {
    align-self: stretch;
    padding: 0 1.88rem 4rem 1.88rem;
  }
  .accueil {
    position: relative;
    background-color: var(--grey-background);
    width: 100%;
    overflow: hidden;
    padding: 0;
    box-sizing: border-box;
    gap: 6.25rem;
    text-align: left;
    font-size: 1.25rem;
    color: var(--blanc);
    font-family: var(--font-open-sans);
    background-image : url("./img/background-home.png");
    background-repeat: no-repeat;
  }

  @media screen and (max-width: 750px) {
    .accueil {
      background-image : none;
    }
  }
  
