
.containerLink{
  text-decoration: none;
  display:flex;
  flex-direction: row;
  color: var(--color);

}
.containerLink :hover{
  color : var(--blue-geogaming);
}
.linkComponent {

    justify-content: flex-end;
    gap: var(--gap-3xs);
    font-size: var(--text-semibold-size);

    align-items: center;
  }
 
  .iconLink{
    display: flex;
    padding: 0.05988rem 0rem 0.26956rem 0rem;
    justify-content: center;
    align-items: center;
    margin:0;
    font-size:1.4375rem;
  }

  .containerLink .containerchild{
    display:flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }

