@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;700&display=swap");


body {
  margin: 0;
  font-family: 'Comfortaa', cursive,'Open-sans',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: normal;
  color: var(--color);
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1 {
  font-size:3rem;
  font-family: 'Comfortaa';
  margin:0;
  font-weight: 700;
  margin:0;}

h2{
    font-size: 2rem;
    font-family: Comfortaa;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin:0;
    }

h3 {
  font-size: 1.75rem;
  font-family: Comfortaa;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin:0;
}
h4 {
  font-size: 1.5rem;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin:0;
}
p{
  font-size: 1rem;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin:0;
 
}
.commentaire {
  font-size: 0.875rem;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
li{
font-size: 1rem;
font-family: Open Sans;
font-style: normal;
font-weight: 600;
line-height: normal;
}
:root {
  /* fonts */
  --font-open-sans: "Open Sans";
  --h4-comfortaa: Comfortaa;
  --text-semibold: "Open Sans";
  --h1: Comfortaa;

  /* font sizes */
 
  --h4-comfortaa-size: 1.38rem;
  --h1-size: 3rem;
  --text-semibold-size: 1rem;
  --font-size-lgi: 1.19rem;
  --form-label-big-size: 1.25rem;
  --h1-size: 3rem;

  /* Colors */
  --grey-background: #515151;
  --blue-geogaming: #0794e3;
  --blanc: #fff;
  --line-8484-outline-btn: #868686;
  --background-light-grey:#ececec;
  --color: #333;
  --bleu-green : #09F6E8;
  --line : #ccc;
  --prive :#E2A610;
 

  /* Paddings */
  --padding-21xl: 2.5rem;
  --padding-3xs: 0.63rem;
  --padding-mid: 1.06rem;
  --padding-10xl: 1.81rem;
  --padding-base: 1rem;
  --padding-21xl: 2.5rem;

    /* Gaps */
    --gap-21xl: 2.5rem;
    --gap-3xs: 0.63rem;
    
     /* border radiuses */
  --br-23xl: 42px;
}
.error{
  background-color: #FFDBDB;
  color :#D70000;

}