.header {
    align-self: stretch;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-21xl) 0;
    align-items: center;
    position:relative;
  }
  .header,
  .whiteBtn {
    display: flex;
    justify-content: center;
  }

  
  .geogamingWrapper,
  .whiteLogoMaster {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
