.containerMonprofil{
    position: relative;
}
.icon-button {
    background: none;
    border: none;
    cursor: pointer;
  }
  

  .containerMonprofil  .avatar-white{
font-size :2.3125rem;
color : var(--blanc);
  }
  