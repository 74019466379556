
form {
    display: flex;
    align-self: stretch;
    width:100%;

}
.inputTextBtnLarge {
    font-size: var(--form-label-big-size);
    color: var(--color);
    border-radius: var(--br-23xl);
    background-color: var(--blanc);
    overflow: hidden;
    padding: var(--padding-base) var(--padding-21xl);
    width:100%;
    border: 1px solid var(--ouline-form, #D8D8D8);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  }
  .inputTextLarge :focus {
    border: 1px solid var(--blue-geogaming);
  }

  .containerInputTextBtnLarge{
    display: flex;
    flex-direction: row;
    width:100%;

  }
  .containerInputTextBtnLarge .BtnInput {
    margin-left: -3.125rem; 
   

  }