



  .sinscrire {
    position: relative;
    font-size: var(--h1-size);
    font-family: var(--h1);
  }

  .ouChild {
    flex: 1;
    position: relative;
    border-top: 1px solid var(--blanc);
    box-sizing: border-box;
    height: 0.06rem;
  }
  .ou {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-3xs);
  }

  .imputBackgroundBlack,
  .imputs {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 2.5rem;
    max-width:558px;;
  }



  .politiqueConfidentialit {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: var(--text-semibold-size);
    background-color: var(--grey-background);
    border-radius:2rem;
  }

 
  .linkWhiteWrapper {
    display:flex;
    align-self: stretch;
    padding: 0 var(--padding-3xs);
    align-items: flex-start;
    justify-content: flex-end;
    font-size: var(--text-semibold-size);
    background-color: var(--grey-background);
    padding :0.5rem 0 0.5rem 0.5rem;
  }
  .btnRegistration,
  .RegistrationContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .containerFormRegistration {
    display: flex;
    width: 100%;
    max-width:34.875rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.5rem;
    flex-shrink: 0;
    font-size: var(--font-size-lgi);
  }
    
  .visuelRegistration img {
    width:100%;
    max-width:36.69rem;
     }
  .Registration {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--grey-background);
    width: 100%;
    overflow: hidden;
    text-align: left;
    font-size: var(--form-label-big-size);
    color: var(--blanc);
    font-family: var(--text-semibold);
    background-image : url("./img/backgroundRegistration.png");
    background-repeat: no-repeat;
    min-height: 100vh;
  }
  .containerRegistration {
    display: flex;
    padding: 6.25rem 2.5rem ;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;


  }
  .containerBtnLink{
    width: 100%;
    max-width:34.875rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    flex-shrink: 0;
    font-size: var(--font-size-lgi);
  }
 .nsm7Bb-HzV7m-LgbsSe jVeSEe i5vt6e-Ia7Qfc uaxL4e-RbRzK {
  width:100%;
}
 .btnGoogle{
  display:flex;
  width:100%;
  align-items: center;
  justify-content: center;
 }
