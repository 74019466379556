.popin-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .popin-content {
    display: flex;
    width: 37.3125rem;
    padding: 0.625rem 1.25rem 3.75rem 1.25rem;
    flex-direction: column;
    align-items: center;
    gap: 0.625rem;
    border-radius: 1.875rem;
    border: 1px solid var(--ouline-form, #D8D8D8);
    background: var(--blanc);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: var(--color);
  }
  .container-pop-in {
    display: flex;
    width:100%;
    margin:auto;
    flex-direction: column;
    align-items: center;
    gap: 1.25rem;
    align-self: stretch;
  }
 .titlePopIn {
    text-align: center;
  }
  .contentPopIn{
    text-align: center;
  }
  .containerPopInBtnExit{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.625rem;
    align-self: stretch;
  }
  .containerPopInBtnExit .pictoExitButton {
    text-decoration:none;
    border :none;
    border-radius:25rem;
    padding : 0.5rem 0.6rem;
    background-color : var(--blanc); 
  }
  .containerPopInBtnExit .pictoExitButton:hover{
    background-color : var(--background-light-grey); 
  }
  .container-content-pop-in{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.875rem;
  }
