.containerBtnSmall{
  text-decoration:none;
  align-items: flex-start;
  flex-direction: column;
}
.masterBtnSmall {
  display: flex;
  padding: 0.625rem 1.25rem;
  align-items: center;
  gap: 0.625rem;
  border-radius: 1.875rem;
  border: 1px solid var(--line-8484-outline-btn);
  background: var(--blanc, #FFF);
  color : var(--color);

  }
  .masterBtnSmall:hover {
    color: var(--blanc);
  background: linear-gradient(270deg, #3ed1be, #2196f3);
}
.masterBtnSmall .iconBtn {
  width: 1.07781rem;
  height: 1.07781rem;
  flex-shrink: 0;
  fill: var(--blanc);
}
