.img-empty-table{
    display: flex;
    width: 23.875rem;
    height: 17.625rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem; 
    margin : auto;
}
.containerTextEmptyImage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
    text-align: center;
    margin : auto;
}