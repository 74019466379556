.whiteLogoMasterChild {
    position: relative;
   
  }
  .whiteLogoMasterChild img {
    width: 3rem;
    height: 3rem;
  }
  .geogamingV2 {
    position: relative;
    font-weight: 600;
    color : var( --blanc);
    font-family: var(--font-open-sans);
    
  }
  .hovered .geogamingV2{
  color : var( --color);

  }
  .logoBlanc {
    position: relative;
    width: 13rem;
    height: 3rem;
  }
  .logo,
  .masterBtnMedium {
    display: flex;
    justify-content: flex-start;
  }
  .logoWrapper {
    position: relative;
  }
  
  .hovered .hoverImage {
    opacity: 1;
  }
  
  .hoverImage {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .logoImage:hover .hoverImage {
    opacity: 1;
  }

  .whiteLogoMaster {
    gap: 0.56rem;
    display: flex;
    width: 13rem;
    height: 3rem;
    text-decoration: none;
    align-items: center;
  }
  .geogamingV2Wrapper{
    display: flex;
    padding: 0.5rem 0.625rem 0.625rem 0rem;
    align-items: flex-start;
    gap: 0.625rem;
  }
  