
.containerEditor{
    background-color: var(--background-light-grey);
    min-height: 100vh;
    display:flex;
    flex-direction:column;
    align-items:stretch;
}
.containerEditor .containerChild{
    display: flex;
    padding: 2.5rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1.25rem;
    align-self: stretch;
    margin : 0 auto 0 auto;
    width: 100%;
    max-width: 78.75rem;
    flex-shrink: 0;

}
.containerEditor h2{
    display: flex;
    padding: 0.625rem 0rem;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
}
.containerEditor .containerChild-1{
    display: flex;
    padding: 1.25rem 1.25rem 2.5rem 1.25rem;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch; 
    border-radius: 0.5rem;
    background: var(--blanc, #FFF);
}
.containerEditor .tableLineBtn{
    display: flex;
    max-width: 76.6875rem;
    padding: 0.625rem 0rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    align-self: stretch;
    border: 1px dashed var(--line, #E0E0E0);
}
.containerEditor .tableEditor1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;  
}
.containerEditor .tableEditor1 tbody{
    display: flex;
    align-self: stretch; 
    flex-direction:column;
}
.containerEditor .tableEditor1 tr{
    display: flex;
    padding: 0.9375rem 0.625rem;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
    flex: 1 0 0;
    border-bottom: 1px solid var(--line);

}
.containerEditor .containerChild-1 .title{
    display: flex;
    padding: 1.25rem 0rem 0.625rem 0rem;
    align-items: flex-start;
    gap: 0.625rem;
    align-self: stretch;
}
