.headerBlue {
  display: flex;
  padding: 0.875rem 1.25rem;
  align-items: center;
  flex-direction: row;
    background-color:var(--blue-geogaming);
    gap: 2.5rem;
    
   
  }
  .headerBlue .logoV2{
    display: flex;
  flex-direction: column;
 position :relative;
 margin:-2px;
 
  }
 
  .headerBlue .containerMenueBlue{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}
.headerBlue .itemContainer{
  display: flex;
  height: 1.5rem;
  align-items: flex-start;
  gap: 2.5rem;
  margin:0;
}
.headerBlue .item{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.0625rem;
  margin:0;
  color:var(--blanc);
}
.headerBlue .monProfilIcon {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.headerBlue .containerLinkFormulas {
  background-color: var(--blanc);
  border-radius: 1.25rem;
  display: flex;
  padding: 0.3125rem 1.25rem;
  align-items: center;
  gap: 0.625rem;
}