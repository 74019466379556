.containerBtn{
    text-decoration:none;
   align-items: flex-start;
   flex-direction: column;
}
.masterBtnLarge{
    border-radius: 36px;
    background-color: var(--blanc);
    border: 1px solid var(--line-8484-outline-btn);
    flex-direction: row;
    padding: 1.0625rem 1.8125rem;
    align-items: flex-start;
    font-size: 1.1875rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    color: var(--color);
    display:flex;
    justify-content:center;
    align-items:center;
    gap : 0.625rem;
  
  }
  .masterBtnLarge:hover {
    color: var(--blanc);
    background: linear-gradient(270deg, #3ed1be, #2196f3);
}

