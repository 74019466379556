.popover {
    position: absolute;
    top: 100%;
    display: flex;
    width: 15.5625rem;
    padding: 0.625rem 1.25rem;
    flex-direction: column;
    align-items: flex-start;
   
    border-radius: 0.3125rem;
    border: 1px solid var(--background-light-grey, #ECECEC);
    background: #FFF;
    /* bloc shadow */
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  }
  
  .popover .link-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    
  }
  .popover .link-item{
    color: var(--color);
  }
 
  .popover .link-list li a {
    color: #000;
    text-decoration: none;
  }
  
 