@font-face {
  font-family: 'icomoon';
  src:  url('./icomoon.eot?qtgdah');
  src:  url('./icomoon.eot?qtgdah#iefix') format('embedded-opentype'),
    url('./icomoon.ttf?qtgdah') format('truetype'),
    url('./icomoon.woff?qtgdah') format('woff'),
    url('./icomoon.svg?qtgdah#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-avatar:before {
  content: "\e900";
}
.icon-back:before {
  content: "\e901";
}
.icon-calendar:before {
  content: "\e902";
}
.icon-calendar-1:before {
  content: "\e903";
}
.icon-checklist:before {
  content: "\e904";
}
.icon-checklist-1:before {
  content: "\e905";
}
.icon-compass:before {
  content: "\e906";
}
.icon-compass-detailed .path1:before {
  content: "\e907";
  color: rgb(226, 166, 16);
}
.icon-compass-detailed .path2:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(255, 202, 40);
}
.icon-compass-detailed .path3:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(226, 166, 16);
}
.icon-compass-detailed .path4:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(255, 202, 40);
}
.icon-compass-detailed .path5:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-compass-detailed .path6:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(224, 195, 171);
}
.icon-compass-detailed .path7:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(178, 148, 124);
}
.icon-compass-detailed .path8:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(33, 33, 33);
}
.icon-compass-detailed .path9:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(244, 67, 54);
}
.icon-compass-detailed .path10:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(47, 120, 137);
}
.icon-compass-detailed .path11:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(148, 209, 224);
}
.icon-compass-detailed .path12:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(244, 67, 54);
}
.icon-compass-detailed .path13:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(178, 148, 124);
}
.icon-compass-detailed .path14:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(224, 195, 171);
}
.icon-compass-detailed .path15:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(178, 148, 124);
}
.icon-compass-detailed .path16:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(224, 195, 171);
}
.icon-compass-detailed .path17:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(255, 245, 157);
}
.icon-dashboard:before {
  content: "\e918";
}
.icon-delete:before {
  content: "\e919";
}
.icon-down:before {
  content: "\e91a";
}
.icon-envelope-outline:before {
  content: "\e91b";
}
.icon-exit:before {
  content: "\e91c";
}
.icon-feather:before {
  content: "\e91d";
}
.icon-Google .path1:before {
  content: "\e91e";
  color: rgb(255, 193, 7);
}
.icon-Google .path2:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(255, 61, 0);
}
.icon-Google .path3:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(76, 175, 80);
}
.icon-Google .path4:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(25, 118, 210);
}
.icon-histogram:before {
  content: "\e922";
}
.icon-home:before {
  content: "\e923";
}
.icon-import:before {
  content: "\e924";
}
.icon-invoice:before {
  content: "\e925";
}
.icon-logout:before {
  content: "\e926";
}
.icon-magnifying-glass:before {
  content: "\e927";
}
.icon-more:before {
  content: "\e928";
}
.icon-payment:before {
  content: "\e929";
}
.icon-peoples:before {
  content: "\e92a";
}
.icon-play2:before {
  content: "\e92b";
}
.icon-presentation:before {
  content: "\e92c";
}
.icon-reduce:before {
  content: "\e92d";
}
.icon-star-filed:before {
  content: "\e92e";
}
.icon-star:before {
  content: "\e92f";
}
.icon-threeDots:before {
  content: "\e930";
  color: #a4a4a4;
}
