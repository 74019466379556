.iconNumberContainer{
    display: flex;
    width: 5.3125rem;
    padding: 0 0.625rem 0 0.625rem;
    justify-content: center;
    align-items: center;
    gap: 0.3125rem;
    border-right: 1px solid var(--line);
}
.iconNumberContainer p{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 0; 
}
.iconNumberContainer .iconStyle{
    font-size: 1.25rem;
    flex-shrink: 0;  
}
