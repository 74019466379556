
form {
    display: flex;
    align-self: stretch;

}
.containerInput {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap:0.5rem;
  }
  
  .containerInput .error {
    max-width: 34.875rem;
    border-radius: 2rem;
    display: block;
    font-size:1rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
  
   
  }
  
  .inputTextLarge {
    font-size: var(--form-label-big-size);
    color: var(--color);
    border-radius: var(--br-23xl);
    background-color: var(--blanc);
    overflow: hidden;
    padding: var(--padding-base) var(--padding-21xl);
    max-width: 34.875rem;
    display: flex;
  }
  