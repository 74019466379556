.stateContainer{
    display: flex;
    width: 4rem;
    align-items: center;
    gap: 0.3125rem;
}
.stateContainer .containerron{
    display: flex;
    align-items: center;
    align-self: stretch;
}
.stateContainer .rond{
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 0.75rem;


}
.stateContainer p{
    display: flex;
align-items: flex-start;
}