.containerEditor .tableEditor1 .Icontext{
    display: flex;
    align-items: center;
    gap: 0.875rem;
    flex: 1 0 0;  
}
.containerEditor .tableEditor1 .iconJourney{

    flex-direction: column;
    align-items: center;
    display: flex;
    width: 2rem;
    padding: 0.25rem 0.125rem;
    border-radius: 0.3125rem;
    border: 1px solid var(--line, #CCC);
    
}
.containerEditor .tableEditor1 .statistique {
    display: flex;
    align-items: flex-start;
}