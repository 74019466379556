.containerBtn{
  text-decoration:none;
  align-items: flex-start;
  flex-direction: column;
}
.masterBtnMedium {
  display:flex;
    border-radius: 30px;
    background-color: var(--blanc);
    border: 1px solid var(--line-8484-outline-btn);
    flex-direction: row;
    padding: 0.88rem 1.5rem;
    align-items: center;
    font-size: 0.94rem;
    color: var(--color);
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    justify-content: flex-start;
    gap : 0.625rem;
  }
  .masterBtnMedium:hover {
    color: var(--blanc);
  background: linear-gradient(270deg, #3ed1be, #2196f3);
}
.masterBtnMedium .iconBtn {
  font-size: 1.07781rem;
  flex-shrink: 0;
  fill: var(--blanc);
}
