.itemMenuHorizontal {
    text-decoration: none;
    color: var(--blanc);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2625rem;
}

.rectangleMenuHorizontal {
    height: 0.375rem;
    align-self: stretch;
    background-color: var(--blanc);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.itemMenuHorizontal:hover .rectangleMenuHorizontal,
.itemMenuHorizontal.active .rectangleMenuHorizontal {
    opacity: 1;
}

.itemMenuHorizontal:hover .rectangleMenuHorizontal {
    background: linear-gradient(301deg,#40D6BB, #02B0FD);
}

.TextMenuHorizontal {
    display: flex;
    align-items: flex-start;
    gap: 0.625rem;
}
