.containerKindOfJourney {
    display: flex;
    padding: 0rem 4.0625rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    align-self: stretch;
  }
  
  .containerCards {
    display: flex;
    padding: 0.625rem 0rem;
    justify-content: center;
    align-items: flex-start;
    gap: 2.5rem;
    align-self: stretch;
  }
  
  @media (max-width: 768px) {
    .containerKindOfJourney {
      padding: 0rem 2rem; /* Ajuster le padding pour les écrans plus petits */
    }
  
    .containerCards {
      flex-direction: column; /* Passer à la disposition en colonne */
      gap: 1.25rem; /* Ajuster l'espace entre les cartes */
      align-items: center; /* Centrer les cartes horizontalement */
    }
  }
  